<template>
	<div id="main">
		<div class="app-container">
			<div class="icon-container">
				<svg
					class="icon-time"
					viewBox="-110 0 512 256"
					xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M0 176C0 167.163 7.16345 160 16 160H116C124.837 160 132 167.163 132 176V276C132 284.837 124.837 292 116 292H16C7.16345 292 0 284.837 0 276V176ZM16 198.5C16 186.074 26.0736 176 38.5 176H93.5C105.926 176 116 186.074 116 198.5V253.5C116 265.926 105.926 276 93.5 276H38.5C26.0736 276 16 265.926 16 253.5V198.5ZM52.8 202C46.8353 202 42 206.835 42 212.8V239.2C42 245.165 46.8353 250 52.8 250H79.2C85.1647 250 90 245.165 90 239.2V212.8C90 206.835 85.1647 202 79.2 202H52.8Z"
						fill="var(--tg-theme-accent-text-color)" />
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M160 16C160 7.16344 167.163 0 176 0H276C284.837 0 292 7.16344 292 16V116C292 124.837 284.837 132 276 132H176C167.163 132 160 124.837 160 116V16ZM176 38.5C176 26.0736 186.074 16 198.5 16H253.5C265.926 16 276 26.0736 276 38.5V93.5C276 105.926 265.926 116 253.5 116H198.5C186.074 116 176 105.926 176 93.5V38.5ZM212.8 42C206.835 42 202 46.8353 202 52.8V79.2C202 85.1647 206.835 90 212.8 90H239.2C245.165 90 250 85.1647 250 79.2V52.8C250 46.8353 245.165 42 239.2 42H212.8Z"
						fill="var(--tg-theme-accent-text-color)" />
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M0 16C0 7.16344 7.16345 0 16 0H116C124.837 0 132 7.16344 132 16V116C132 124.837 124.837 132 116 132H16C7.16345 132 0 124.837 0 116V16ZM16 38.5C16 26.0736 26.0736 16 38.5 16H93.5C105.926 16 116 26.0736 116 38.5V93.5C116 105.926 105.926 116 93.5 116H38.5C26.0736 116 16 105.926 16 93.5V38.5ZM52.8 42C46.8353 42 42 46.8353 42 52.8V79.2C42 85.1647 46.8353 90 52.8 90H79.2C85.1647 90 90 85.1647 90 79.2V52.8C90 46.8353 85.1647 42 79.2 42H52.8Z"
						fill="var(--tg-theme-accent-text-color)" />
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M215.5 207C211.082 207 207.5 210.582 207.5 215V237C207.5 241.418 211.082 245 215.5 245H237.5C241.918 245 245.5 241.418 245.5 237V215C245.5 210.582 241.918 207 237.5 207H215.5ZM221.5 217C219.291 217 217.5 218.791 217.5 221V231C217.5 233.209 219.291 235 221.5 235H231.5C233.709 235 235.5 233.209 235.5 231V221C235.5 218.791 233.709 217 231.5 217H221.5Z"
						fill="var(--tg-theme-accent-text-color)" />
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M240.5 232C236.082 232 232.5 235.582 232.5 240V279C232.5 283.418 236.082 287 240.5 287H279.5C283.918 287 287.5 283.418 287.5 279V240C287.5 235.582 283.918 232 279.5 232H240.5ZM247.5 241C244.186 241 241.5 243.686 241.5 247V272C241.5 275.314 244.186 278 247.5 278H272.5C275.814 278 278.5 275.314 278.5 272V247C278.5 243.686 275.814 241 272.5 241H247.5Z"
						fill="var(--tg-theme-accent-text-color)" />
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M173.5 232C169.082 232 165.5 235.582 165.5 240V279C165.5 283.418 169.082 287 173.5 287H212.5C216.918 287 220.5 283.418 220.5 279V240C220.5 235.582 216.918 232 212.5 232H173.5ZM180.5 241C177.186 241 174.5 243.686 174.5 247V272C174.5 275.314 177.186 278 180.5 278H205.5C208.814 278 211.5 275.314 211.5 272V247C211.5 243.686 208.814 241 205.5 241H180.5Z"
						fill="var(--tg-theme-accent-text-color)" />
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M240.5 165C236.082 165 232.5 168.582 232.5 173V212C232.5 216.418 236.082 220 240.5 220H279.5C283.918 220 287.5 216.418 287.5 212V173C287.5 168.582 283.918 165 279.5 165H240.5ZM249.5 174C245.082 174 241.5 177.582 241.5 182V203C241.5 207.418 245.082 211 249.5 211H270.5C274.918 211 278.5 207.418 278.5 203V182C278.5 177.582 274.918 174 270.5 174H249.5Z"
						fill="var(--tg-theme-accent-text-color)" />
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M173.5 165C169.082 165 165.5 168.582 165.5 173V212C165.5 216.418 169.082 220 173.5 220H212.5C216.918 220 220.5 216.418 220.5 212V173C220.5 168.582 216.918 165 212.5 165H173.5ZM180.5 174C177.186 174 174.5 176.686 174.5 180V205C174.5 208.314 177.186 211 180.5 211H205.5C208.814 211 211.5 208.314 211.5 205V180C211.5 176.686 208.814 174 205.5 174H180.5Z"
						fill="var(--tg-theme-accent-text-color)" />
				</svg>
			</div>
			<br />
			<h1 class="app-title">QR-scanner</h1>
			<div class="task-counter">
				<button @click="closeTMA">
					Close the scanner
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="var(--tg-theme-accent-text-color)"
						class="bi bi-x-square-fill"
						viewBox="0 -3 16 16">
						<path
							d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
					</svg>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			f: 0
		}
	},
	mounted() {
		this.TMA.ready()
		this.TMA.expand()
		this.TMA.MainButton.setText('Open QR-scanner ')
		this.TMA.MainButton.show()
		this.TMA.onEvent('qrTextReceived', this.processQRCode)
		this.TMA.onEvent('mainButtonClicked', this.mainButtonClicked)
		this.showQRScanner()
	},
	methods: {
		showQRScanner() {
			console.log('showQRScanner')
			this.TMA.showScanQrPopup({
				text: 'Point to the QR code'
			})
		},
		processQRCode(data) {
			if (data.data.length > 1024) {
				this.TMA.sendData('Error')
				return
			}
			this.TMA.HapticFeedback.impactOccurred('rigid')
			this.TMA.HapticFeedback.impactOccurred('heavy')
			this.TMA.closeScanQrPopup()
			this.TMA.sendData(JSON.stringify(data))
      this.TMA.close()
		},
		mainButtonClicked() {
			this.showQRScanner()
		},
		closeTMA() {
			this.TMA.close()
		}
	}
}
</script>

<style>
#main {
	background-color: var(--tg-theme-bg-color, white);
	color: var(--tg-theme-text-color, black);
}

.app-container {
	margin-top: 50px;
	color: var(--tg-theme-text-color, black);
	font-family: Arial, sans-serif;
}

.icon-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	border-radius: 8px;
}

.icon-time {
	width: 512px;
	height: 256px;
}

.app-title {
	font-size: 32px;
	text-align: center;
}

.task-counter {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

button {
	font-family: Arial, sans-serif;
	font-size: 16px;
	padding: 10px 20px;
	border: none;
	border-radius: 10px;
	background-color: var(--tg-theme-header-bg-color);
	color: var(--tg-theme-text-color);
	outline: none;
	cursor: pointer;
	transition: background-color 0.3s, box-shadow 0.3s;
	text-align: center;
	user-select: none;
}

button {
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	transform: translateY(2px);
}
</style>
